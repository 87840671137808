<!--  -->
<template>
  <div class="node-add">
    <v-menu offset-y max-height="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="node-add-btn"
          fab
          dark
          x-small
          color="primary"
          v-bind="{ ...$attrs, ...attrs }"
          v-on="{ ...$listeners, ...on }"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-list width="180" subheader dense>
        <v-list-item @click="handleAdd(branches)">
          <v-list-item-title>添加分支</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-subheader>工序</v-subheader>
        <v-list-item v-for="(item, index) in processItems" :key="index" @click="handleAdd(item)">
          <v-list-item-title>{{ item.Name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import getProcess from '@/api/Process'
export default {
  props: ['selected'],
  data() {
    return {
      processes: [],
      branches: {
        isBranch: 1,
        branches: [[], []],
      },
    }
  },
  computed: {
    processItems() {
      return this.processes.filter(o => !this.selected?.some(el => o.Id === el.Id))
    },
  },
  methods: {
    handleAdd(ev) {
      this.$emit('add', this.$$.jsonCopy(ev))
    },
  },
  async mounted() {
    const res = await getProcess()

    this.processes = res
  },
}
</script>

<style lang="scss" scoped>
.node-add {
  width: 220px;
  text-align: center;
  position: relative;
  // border: 1px solid green;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 2px;
    height: 100%;
    background-color: #cacaca;
  }
  .node-add-btn {
    margin: 20px 0 35px;
  }
}
</style>
