import Vue from 'vue'
let ops = null
export default async function getEq(refresh) {
  if (!ops || refresh) {
    ops = Vue.prototype.$ajax(`/Main/Process/GetAllSimpleList`)
  }
  return await ops
}
export function refresh() {
  ops = null
}
